import Layout from "./pages/Layout";
import TikTakToe from './games/tik-tak-toe/tik_tak_toe';
import './App.css';
import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <Routes>
        <Route path="/" element={<Layout />} />
        <Route path="/tiktaktoe" element={<TikTakToe />} />
    </Routes>
  );
}

export default App;
