import styled from 'styled-components';
import React from 'react';
import o from './img/o.webp';
import x from './img/x.webp';
import blank from './img/blank.webp';
import { Helmet } from 'react-helmet';

const MainTable = styled.table`
border: 2px solid;
text-align: center;
border-collapse:collapse;
`;
const ImageStyled = styled.img`
width: 200px;
height:200px;
`;
const TableCell = styled.td`
border:1px solid;
`;

const MainDiv = styled.section`
position: absolute;
left: 50%;
top: 50%;
-webkit-transform: translate(-50%, -50%);
transform: translate(-50%, -50%);`;

const TurnDiv = styled.div`
margin:15px;
text-align: center;
`;

var turn = true;
var values = [[], [], []];
var movement = 0;
var lock = false;
var win = false;

class TikTakToe extends React.Component {
    constructor(props) {
        super(props)
        this.handler = this.handler.bind(this)
    }

    handler() {
        this.setState({
            movement: 0
        })
    }
    render() {
        return (
            <>
                <Helmet>
                    <title>TikTakToe</title>
                </Helmet>
                <MainDiv>
                    <TurnDiv>
                        <h1>Turn: {turn ? "X" : "O"}</h1>
                        <h1>{win ? "Win:" + (turn ? "O" : "X") : ""}</h1>
                        <h2>Move: {movement}</h2>
                    </TurnDiv>
                    <div>
                        <MainTable>
                            <tbody>
                                <Row row='0' handler={this.handler} ></Row>
                                <Row row='1' handler={this.handler} ></Row>
                                <Row row='2' handler={this.handler} ></Row>
                            </tbody>
                        </MainTable>
                    </div>
                </MainDiv>
            </>
        );
    }
};

class Row extends React.Component {
    render() {
        return <tr>
            <Cell col='0' row={this.props.row} handler={this.props.handler}></Cell>
            <Cell col='1' row={this.props.row} handler={this.props.handler}></Cell>
            <Cell col='2' row={this.props.row} handler={this.props.handler}></Cell>
        </tr>
    }
}
class Cell extends React.Component {
    state = {
        set: false,
        ox: false,
    }
    end_game = (winner) => {
        lock = true;
        win = true;
        //alert("Winner: " + (winner ? "Krzyżyk" : "Kółko"));
    }
    check_all = (winner) => {
        if (movement < 5) return;
        if (lock) return;
        for (let i = 0; i <= 2; i++) {
            if (values[i][0] === values[i][1] && values[i][1] === values[i][2] && values[i][1] !== undefined) {
                return this.end_game(winner);
            }
        }
        for (let i = 0; i <= 2; i++) {
            if (values[0][i] === values[1][i] && values[1][i] === values[2][i] && values[0][i] !== undefined) {
                return this.end_game(winner);
            }
        }
        if (values[0][0] === values[1][1] && values[1][1] === values[2][2]) {
            return this.end_game(winner);
        }
        else if (values[2][0] === values[1][1] && values[1][1] === values[0][2]) {
            return this.end_game(winner);
        }
    }
    set_image = () => {
        if (!this.state.set && !lock) {
            this.setState(state => ({ set: true, ox: turn ? true : false }))
            values[this.props.col][this.props.row] = turn;
            this.props.handler();
            movement = movement + 1;
            this.check_all(turn);
            turn = !turn;
        }
    }
    render() {
        return <TableCell>
            <ImageStyled onClick={this.set_image}
                src={this.state.set ? (this.state.ox ? o : x) : blank}></ImageStyled>
        </TableCell>
    }
}
export default TikTakToe;